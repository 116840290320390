@use 'sass:color';

@import '../../styles/custom';
@import '~bootstrap/scss/mixins';

//
// content-box
.content-box {
  position: relative;
  background-color: $white;
  //box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border: 1px solid $gray-300;
}

.content-box__inner {
  padding: 1.5rem 1.25rem;
  @include media-breakpoint-up(lg) {
    padding: 1.5rem 40px;
  }
}

.content-box__title-wrapper {
  overflow: hidden;
  padding: 2rem 1.25rem;
  border-bottom: 1px solid $gray-300;

  @include media-breakpoint-up(lg) {
    padding: 0 40px;
  }
}

.content-box__title {
  font-weight: 600;
  font-size: 22px;
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.content-box__body {
  position: relative;
  padding: 1.5rem 1.25rem;
  @include media-breakpoint-up(lg) {
    padding: 1.5rem 40px;
  }
}

//
// content-filters
.content-filters {
  @include make-row();
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  //justify-content: flex-end;
}

.content-filters__item {
  @include make-col-ready();
  flex: 0 0 auto;
  max-width: none;
  padding-left: 0.25rem;
  padding-right: 0.25rem;

  @include media-breakpoint-down(md) {
    @include make-col(3);
  }

  @include media-breakpoint-down(sm) {
    @include make-col(6);
  }

  @include media-breakpoint-down(xs) {
    @include make-col(12);
  }
}

//
// content-title
.content-title {
  background-color: #fff;
  border-bottom: 1px solid $gray-300;
  padding: 1.5rem 15px;
}

.content-title__title {
  font-size: 26px;
  line-height: 30px;
  font-weight: $font-weight-normal;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.content-title__desc {
  margin-top: 0.5rem;
  margin-bottom: 0;
  color: $gray-600;
  max-width: max-content;
  line-height: 1.3;
}

.content-title__count {
  display: inline-block;
  position: relative;
  font-size: 18px;
  height: 30px;
  color: $link-color;
  font-weight: $font-weight-bold;
  margin-left: 30px;

  &:before {
    display: inline-block;
    position: absolute;
    content: '';
    top: 5px;
    left: -15px;
    width: 2px;
    height: 20px;
    background-color: $gray-300;
  }
}

//
// Content Forms
//
// ContentForm
// ContentFieldset
// ContentFieldsetHeader
// ContentLegend
// ContentInput

//
// content-form-wrapper
.content-form-wrapper {
  margin: 0 auto;
  max-width: 720px;
  padding: 2rem 0;

  .form-group {
    margin-bottom: 1.5rem;
  }
}

.content-form {
  padding-top: 2rem;
  padding-bottom: 2rem;

  &:not(:last-child) {
    border-bottom: 1px solid $gray-200;
  }
}

.content-fieldset {
  &:hover {
    legend {
      color: color.adjust($blue, $lightness: 30%, $space: hsl);
    }
  }
}

//
// content-legend
.content-legend {
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 600;
  color: $gray-600;
  transition: color 0.5s;
}

$content-field-width: 720px;

//
// content-fields: Grid Wrapper for form fields
.content-fields {
  max-width: $content-field-width;
}

//
// content-fieldset-header

.content-fieldset {
}

.content-fieldset__header {
  margin-bottom: 0.5rem;
}

.content-fieldset__header__desc {
  max-width: $content-field-width;
  line-height: 1.3;
  color: $gray-600;
}

//
// content-table
.content-table {
  thead {
    border-bottom: 1px solid $gray-200;
  }

  th {
    font-weight: 600;
    color: $gray-700;
  }

  th {
    white-space: nowrap;
    vertical-align: middle;
  }

  td {
    vertical-align: top;
  }
}

.content-table-v2 {
  border: 1px solid $gray-300;
  border-top: none;
  border-bottom: none;

  thead {
    background-color: $gray-100;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  th {
    font-weight: 600;
    color: $gray-600;
    vertical-align: middle;
    white-space: nowrap;
  }

  td {
    vertical-align: middle;
    white-space: nowrap;
  }
}

.content-table-v2--nowrap {
  @extend .content-table-v2;

  td {
    white-space: normal !important;
  }
}

.sort-stateful__container {
  position: relative;
  margin-top: 3px;
  margin-bottom: auto;
}

.sort-stateful__icon {
  cursor: pointer;
  color: $gray-400;
  position: absolute;
  z-index: 1 !important;
}

.sort-stateful__icon--active {
  cursor: pointer;
  color: $primary;
  position: absolute;
  z-index: 2 !important;
}

//
// content-pagination
.content-pagination {
  overflow: hidden;
  .pagination {
    margin: 0;
    float: right;
  }
}

//
// content-profile
%make-column {
  @include make-col-ready();
  padding: 0;
  overflow: hidden;
}

.content-profile {
  @include make-row();
  margin: 0;
  min-width: 200px;
  align-items: center;
}

.content-profile__image {
  @extend %make-column;
  margin-right: 10px;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.content-profile__detail {
  @extend %make-column;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//
// content-nav
.content-nav {
  border-bottom: 1px solid $gray-300;
  font-size: 16px;
  background-color: #fff;
  padding-left: 15px;
  padding-right: 15px;

  a {
    position: relative;
    font-weight: 600;
    color: $gray-600;

    &.active {
      color: $link-color;

      &:after {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 3px;
        width: 100%;
        content: '';
        background-color: $blue;
      }
    }
  }

  a[class='nav-link active'] {
    color: $link-color;

    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 3px;
      width: 100%;
      content: '';
      background-color: $blue;
    }
  }
}

//
// content-breadcrumb
.content-breadcrumb {
  border-bottom: 1px solid $gray-300;
  .breadcrumb {
    margin-bottom: 0;
  }
}

@mixin sorting {
  position: absolute;
  color: $gray-400;
  z-index: 1;
  margin-top: 2px;
  cursor: pointer;
}

// sorting
.content-table-sorting {
  @include sorting;
}

.content-table-sorting--active {
  @include sorting;
  color: $primary;
  z-index: 2;
}

.content-avatar {
  position: relative;
  border-radius: 50%;
}

.content-avatar--editable {
  cursor: pointer;
}

.content-avatar__status {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.content-avatar__status__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border: 1px solid $gray-400;
  border-radius: 50%;
  width: 1.65rem;
  height: 1.65rem;
}

:global(.table-responsive) {
  overflow: auto !important;
}
